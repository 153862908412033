.container {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: var(--color-main);
  padding: 5px 10px;
  height: 36px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px;
  margin-right: 15px;
  background: var(--color-header-second);
  transition: all 0.3s;

  &:hover {
    opacity: 0.5;
  }

  & svg {
    position: relative;
    top: -1px;
  }
}

@media screen and (max-width: 764px) {
  .container {
    display: none;
  }
}
