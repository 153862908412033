.container {
  position: fixed;
  width: 100vw;
  z-index: 10;
  padding: 0 40px;
  background-color: var(--color-header-main);
  border-bottom: 1px solid var(--color-header-second);
  box-shadow: var(--shadow-header);

  & .top {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .actions {
      display: flex;
      align-items: center;
      gap: 10px;

      & .switcher {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 42px;
        gap: 10px;
        padding: 0 10px;
        border-radius: 5px;
        transition: all 0.3s;

        & button {
          width: 40px;
          height: 20px;

          &:focus {
            outline: none;
            box-shadow: none !important;
          }
        }

        &:hover {
          background-color: var(--color-header-second);
        }
      }

      & .btnWrapper {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 8px;
        padding: 10px;
        transition: all 0.3s;
        border-radius: 5px;

        &:hover {
          background-color: var(--color-header-second);
        }

        & .btn {
          display: flex;
          align-items: center;
          gap: 5px;
        }

        & .tag {
          position: relative;
          top: 0;
          font-size: 10px;
          font-weight: bold;
          width: 15px;
          height: 15px;
          padding: 9px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          color: var(--color-green);
          background: var(--badge-bg-green);
          box-shadow: 0 0 10px 0px rgb(0 0 0 / 30%);
        }
      }

      & .dropdown {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 10px;
        transition: all 0.3s;
        border-radius: 5px;

        &:hover {
          background-color: var(--color-header-second);
        }

        & .backdrop {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 10px;

          .icon {
            height: 22px;

            svg {
              padding: 3px;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1160px) {
  .container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 700px) {
  .container {
    & .top {
      & .actions {
        & .btnWrapper {
          &:nth-child(1),
          &:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 555px) {
  .container {
    padding: 0 10px;
  }
}
