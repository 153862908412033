.dark {
  --color-mainBg: #27282c;
  --color-elemBg: #343740;
  --color-main: #fff;
  --color-red: #d94c48;
  --color-blue: #009ef7;
  --color-green: #25ca7f;
  --color-grey: #616172;
  --color-font: white;
  --color-yellow: #ffb74d;
  --color-violet: #984af6;
  --color-border: #27282c;
  --border-green: #1a3c3a;
  --color-border-title: #27282c;
  --hover-table: #1e1f22;
  --badge-bg-main: rgba(255, 255, 255, 0.1);
  --badge-bg-red: rgba(239, 83, 80, 0.15);
  --badge-bg-blue: rgba(0, 158, 247, 0.2);
  --badge-bg-green: rgba(37, 202, 127, 0.1);
  --switch-bg: #25535c;
  --font-size-small: 0.6rem;
  --font-size: 0.8125rem;
  --font-size-big: 1.3rem;
  --font-size--header: 0.9375rem;
  --color-logo-main: white;
  --color-logo-second: #32a9a3;
  --color-header-main: #343740;
  --color-header-second: #27282c;
  --color-btn-menu: rgba(255, 255, 255, 0.1);
  --color-btn-menu-hover: rgba(37, 202, 127, 0.1);
  --color-btn-title-hover: #25ca7f;
  --color-arrows-date: white;
  --notification-error: rgba(239, 83, 80, 0.15);
  --notification-success: rgba(37, 202, 127, 0.2);
  --login-flecker-bg: #242529;
  --import-img-opacity: 0.2;
  --input-focus: rgba(255, 255, 255, 0.2);
  --shadow-box: none;
  --shadow-drop: 0 5px 20px 0px rgba(0, 0, 0, 0.3);
  --shadow-header: none;
  --not-found: url(../../public/img/icon/clipboard-times-dark.svg);
  --logo: url(../assets/images/logo-dark.png);
}
