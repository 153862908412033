.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 275px;
  padding: 10px;
  margin: 14px 20px 0px 10px;
  border-radius: 10px;
  background-color: var(--color-elemBg);

  & .menu {
    & .subtitle {
      color: var(--color-font);
      font-weight: bold;
      text-transform: uppercase;
      font-size: 11px;
      padding: 10px 18px;
      opacity: 0.4;
    }
  }

  & .support,
  & .tele {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-main);
    padding: 10px !important;
    font-size: 14px;
    font-weight: 700;
    margin-top: 50px;
    border-radius: 10px;
    font-family: 'Nunito', sans-serif;
    overflow: hidden;
    text-decoration: none;
    background-color: var(--color-btn-menu);
    transition: all 0.3s;

    svg {
      margin-left: 5px;
      transition: all 0.3s;
    }

    &:hover {
      color: var(--color-mainBg) !important;
      background-color: var(--color-btn-menu-hover);

      svg {
        transform: translateX(100px);
      }
    }
  }

  & .tele {
    margin-top: 10px;
  }
}

.mobileMenu {
  cursor: pointer;
  user-select: none;
  position: absolute;
  display: none;
  align-items: center;
  justify-content: space-between;
  top: 83px;
  left: 20px;
  width: calc(100% - 40px);

  & .btn {
    display: flex;
    align-items: center;
    padding: 5px;
    font-weight: 500;
    border-radius: 5px;
    background: var(--color-elemBg);

    & svg {
      position: relative;
      top: -1px;
      margin-right: 5px;
    }
  }
}

.bg {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: var(--color-mainBg);
  opacity: 0.8;
  cursor: pointer;
  z-index: 5;
  transition: all 0.3s;
}

@media screen and (max-width: 1000px) {
  .sidebar {
    position: fixed;
    left: -300px;
    z-index: 9;
    box-shadow: var(--shadow-drop);
    transition: all 0.3s;
  }

  .mobileMenu {
    display: flex;
  }

  .active {
    left: 0;
  }

  .activeBg {
    display: block;
  }
}
