.container {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;

  & .header {
    position: relative;
    display: flex;
    font-weight: bold;
    font-size: var(--font-size--header);
    padding-bottom: 10px;
    margin-bottom: 20px;
    padding-left: 15px;
    border-bottom: 1px dashed var(--color-border-title);

    &::after {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      top: calc(50% - 9px);
      left: 0;
      border-radius: 50%;
      animation: flicker 1.5s infinite alternate;
    }
  }

  & .current {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    background: var(--color-elemBg);

    & .currentRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      & .title {
        font-weight: bold;
        font-size: 14px;
      }

      & .desc {
        font-weight: bold;
        color: var(--color-main);
      }
    }
  }

  & .listContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & .listTitle {
      font-weight: bold;
    }

    & .list {
      display: flex;
      flex-direction: column;
      gap: 20px;

      & .listItem {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border-radius: 10px;
        outline: 1px dashed var(--color-border);
        outline-offset: 3px;
        background: var(--color-elemBg);
        transition: outline 0.3s;

        & .item {
          & .itemName {
            color: var(--color-main);
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
          }

          & .itemDesc {
            font-size: 12px;
            font-weight: bold;
          }
        }

        & .price {
          display: flex;
          flex-direction: column;
          align-items: center;
          background: var(--color-mainBg);
          padding: 10px;
          border-radius: 10px;
          transition: background 0.3s;

          & .priceTitle {
            color: var(--color-main);
            font-weight: 600;

            & small {
              font-size: 70%;
            }
          }

          & span {
            font-style: italic;
          }
        }
      }

      & .listItem.active {
        outline-color: var(--color-main);

        & .price {
          background: var(--badge-bg-main);
        }
      }
    }
  }

  & .error {
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    padding: 10px;
    color: var(--color-red);
    margin: 10px 0;
  }

  & .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 15px;
    padding-top: 10px;
    border-top: 1px dashed var(--color-border-title);
  }

  & .empty {
    text-align: center;
    font-weight: bold;
  }
}

@keyframes flicker {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    background-color: var(--color-main);
  }

  20%,
  24%,
  55% {
    background-color: var(--color-mainBg);
  }
}
