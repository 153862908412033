.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  & .card {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    box-shadow: none;

    & .img {
      width: 600px;
    }

    & .title {
      color: var(--color-font);
      font-size: 24px;
      color: var(--color-font);
    }
  }
}
