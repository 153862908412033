.wrapper {
  position: absolute;
  height: 290px;
  left: -290px;
  bottom: -300px;
  padding: 15px;
  border-radius: 5px;
  background-color: var(--color-elemBg);
  box-shadow: var(--shadow-drop);
  border: 1px dashed var(--border-green);
  z-index: 50;

  & .drop {
    display: flex;
    flex-direction: column;
    gap: 5px;
    user-select: none;
    color: var(--color-font);
    width: 300px;
    height: 190px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    border-radius: 5px;
    background: var(--color-mainBg);

    & .item {
      background-color: var(--color-elemBg);
      border-radius: 5px;
      padding: 10px;
      cursor: pointer;

      & .itemTitle {
        overflow-x: hidden;
        text-overflow: ellipsis;
      }

      & .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 12px;

        & .itemDate {
          display: flex;
          align-items: center;
          gap: 5px;
          color: var(--color-grey);
        }

        & .isNew {
          color: var(--color-green);
          padding: 3px 8px;
          background: var(--badge-bg-green);
          border-radius: 5px;
        }
      }
    }

    & .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px;
      color: var(--color-font);
      font-weight: bold;
      margin: 32px 0;

      svg {
        margin-bottom: 10px;
      }
    }
  }

  & .drop::-webkit-scrollbar {
    width: 2px;
  }

  & .drop::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--color-border);
    border-radius: 10px;
  }

  & .drop::-webkit-scrollbar-thumb {
    background: var(--color-main);
    border-radius: 10px;
  }

  & .footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    & .btn {
      color: var(--color-green);
      padding: 10px;
      cursor: pointer;
      border: none;
      border-radius: 5px;
      background-color: var(--badge-bg-green);
      transition: all 0.3s;
    }
    & .btn:hover {
      opacity: 0.5;
    }
    & .btn[disabled] {
      opacity: 0.5;
    }
    & .btn[disabled]:hover {
      transform: scale(1);
      cursor: not-allowed;
    }
  }
}

@media screen and (max-width: 555px) {
  .wrapper {
    left: -255px;
  }
}
