.dropContainer {
  & .ant-dropdown-menu {
    margin: 10px;
    padding: 10px;
    background-color: var(--color-elemBg);
    box-shadow: var(--shadow-drop);
    border-radius: 5px;
    border: 1px dashed var(--border-green);

    & .ant-dropdown-menu-item-divider {
      background: var(--color-mainBg);
    }

    & .ant-dropdown-menu-item {
      padding: 8px;
      color: var(--color-font);
      font-weight: bold;
      border-radius: 5px;
      svg {
        margin-right: 10px;
        transition: all 0.3s;
      }
    }

    & .ant-dropdown-menu-item-disabled {
      opacity: 0.5;
    }

    & .ant-dropdown-menu-item:hover {
      background-color: var(--color-mainBg);
      color: var(--color-main);
    }
  }

  & .v2 {
    background-color: var(--color-mainBg);
    border: 1px dashed var(--border-green);

    & .ant-dropdown-menu-item:hover {
      background-color: var(--color-elemBg);
    }
  }
}
