@import './colorPicker.scss';
@import './dropdown.scss';
@import './table.scss';

.ant-col {
  font-family: 'Nunito', sans-serif;
}

p,
.ant-form,
body {
  color: var(--color-font);
  margin-bottom: 0;
  font-family: 'Nunito', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
.ant-typography,
h5.ant-typography {
  color: var(--color-font);
}

.ant-btn {
  font-size: 16px;
  font-weight: 600;
  height: auto;
  min-height: 32px;
  border: none;
  padding: 0px 15px;
  border-radius: 5px;
  background: var(--color-mainBg);

  &:hover,
  &:focus {
    color: var(--color-mainBg);
    background: var(--color-main);
  }
}

a.ant-btn {
  padding-top: 10px !important;
}

//search input
.ant-input-group {
  & .ant-input-affix-wrapper {
    height: auto;
    background-color: var(--color-mainBg);
    border-color: var(--color-mainBg);

    color: var(--color-font);
    & .ant-input:hover,
    & .ant-input:focus {
      border-color: var(--color-mainBg) !important;
      box-shadow: none;
    }
    & input {
      color: var(--color-font);
      background-color: var(--color-mainBg);
      border-color: var(--color-mainBg);
    }

    &:not(:last-child) {
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
  }
  & .ant-input-affix-wrapper:hover,
  & .ant-input-affix-wrapper:focus {
    background-color: var(--color-mainBg);
    border-color: var(--color-mainBg);
    box-shadow: none;
  }
  & .ant-input-group-addon,
  & .ant-input-group-addon:last-child {
    background-color: var(--color-mainBg);
    & .ant-input-search-button {
      color: var(--color-font);
      background-color: var(--color-mainBg);
      border-radius: 0 !important;
      border: none;
      padding: 0;
    }
  }
  & svg {
    fill: var(--color-main);
  }
}

.ant-input-group.ant-input-group-compact > *:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ant-input-group.ant-input-group-compact > *:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.input-login.ant-input-affix-wrapper,
.input-login.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.input-login.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.input-login.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.input-login.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: var(--color-mainBg);
  color: var(--color-font);
}

.ant-input-affix-wrapper,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: var(--color-mainBg);
}

.ant-input-affix-wrapper {
  border: none;
  border-radius: 5px;
  padding: 0 10px 0 0;

  & input.ant-input {
    border: none;
    background-color: var(--color-mainBg);
    padding: 15px;
  }
  & .ant-input-prefix {
    color: var(--color-font);
  }

  &:focus,
  &.ant-input-affix-wrapper-focused {
    border-color: transparent;
    box-shadow: 0 0 0 2px var(--input-focus);
  }
}

.ant-input-group-wrapper {
  width: auto;
}

.input-search {
  padding: 5px;
  border-radius: 5px;

  & .ant-input-group {
    & .ant-input-affix-wrapper {
      background-color: var(--color-mainBg);

      & input.ant-input {
        padding: 7px 15px;
        border-radius: 4px;
        background-color: var(--color-mainBg);
      }
    }

    & .ant-input-group-addon {
      position: relative;
      border-radius: 0 4px 4px 0;
      background-color: var(--color-mainBg);

      & .ant-input-search-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 7px;
        background-color: var(--color-mainBg);
        box-shadow: none;
      }

      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 70%;
        top: 50%;
        left: 0;
        border-left: 1px dashed var(--color-mainBg);
        transform: translateY(-50%);
      }
    }
  }

  &.v2 {
    width: 100%;
    border: none;
    padding-left: 0;

    & .ant-input-group {
      & .ant-input-affix-wrapper {
        & input.ant-input {
          padding: 15px;
        }
      }

      & .ant-input-group-addon {
        & .ant-input-search-button {
          height: 45px;
        }
      }
    }
  }
}

textarea.ant-input {
  &:hover,
  &:focus {
    background: var(--color-mainBg);
  }
  background: var(--color-mainBg);
}

//dropDown menu
.ant-tabs-dropdown {
  color: var(--color-font);
  .ant-tabs-dropdown-menu {
    background-color: var(--color-elemBg);
    & .ant-tabs-dropdown-menu-item {
      color: var(--color-font);
    }
    & .ant-tabs-dropdown {
      color: var(--color-font);
    }
    & .ant-tabs-dropdown-menu-item:hover {
      background-color: var(--color-elemBg);
      opacity: 0.5;
    }
    & a {
      color: var(--color-font);
    }
  }
}

.ant-select-dropdown {
  color: var(--color-font);
  padding: 5px;
  background-color: var(--color-mainBg);
  background: var(--color-mainBg);
  border: 1px dashed var(--border-green);
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.3);

  .ant-select-item-option,
  .ant-select-item-group {
    background-color: var(--color-mainBg) !important;
    color: var(--color-font) !important;
    border-radius: 5px;
    margin: 2px 0;
  }

  .ant-select-item-option-active,
  .ant-select-item-option-selected {
    background-color: var(--color-elemBg) !important;
    border-radius: 5px;
    margin: 2px 0;
  }

  .ant-select-item-option-state {
    color: var(--color-green) !important;
  }
}

.anticon.anticon-down {
  margin-right: 0;
}

//SPIN
.ant-spin {
  color: var(--color-font);
  font-size: 16px;
  font-weight: bold;
  opacity: 0.6;
}

//INPUT
.ant-input,
.antinput.ant-input-outlined {
  box-sizing: border-box;
  border: none;
  background-color: var(--color-mainBg);
  padding: 15px;
  color: var(--color-font);
  border-radius: 5px;
  &::placeholder {
    color: var(--color-font);
    opacity: 0.4;
  }
  &:hover,
  &:focus {
    background-color: var(--color-mainBg);
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--input-focus);
  }
}

.ant-input[disabled] {
  color: var(--color-font);
  background-color: var(--color-mainBg);
  border-color: var(--color-border);
  opacity: 0.6;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  background-color: var(--color-mainBg);
  transition: background-color 600000s 0s, color 600000s 0s;
}

.ant-form-item-explain-error {
  color: var(--color-red);
  padding: 5px;
}

//INPUT PASSWORD ICON
.ant-input-password-icon {
  cursor: pointer;
  display: flex;
  height: 35px;
  padding: 0 0 0 10px;
  align-items: center;
  border-left: 1px solid var(--color-elemBg);
}

//TABLE
.ant-table {
  display: contents;
  color: var(--color-font);
  font-size: var(--font-size);
  border-radius: 10px;
  background: var(--color-elemBg);

  & .ant-table-content {
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: var(--color-mainBg);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-grey);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      cursor: pointer;
      background: var(--color-grey);
    }

    & .ant-table-tbody > tr > td {
      text-align: center;
      border: none;
      background: var(--color-mainBg);
      white-space: nowrap;
      font-family: 'Nunito', sans-serif;
    }

    & .ant-table-tbody > tr {
      &:first-child {
        td {
          &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
          }

          &:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }
        }
      }
    }
  }

  & .ant-table-row:hover,
  & .ant-table-cell,
  & .ant-table-cell-row-hover,
  td,
  & .ant-table-tbody > tr.ant-table-row:hover > td,
  & .ant-table-tbody > tr > td.ant-table-cell-row-hover,
  & .ant-table-tbody > tr.ant-table-placeholder:hover > td,
  .ant-table-tbody .ant-table-row.ant-table-row-selected > .ant-table-cell {
    background: var(--hover-table);
    color: var(--color-font);
    &:last-child {
      background: var(--hover-table);
    }
  }
  & .ant-table-thead th.ant-table-column-sort {
    color: var(--color-main);
    background: transparent;
  }
  & .ant-table-thead > tr > th {
    font-weight: bold;
    border: none;
    background: transparent;
    text-align: center;
    //white-space: nowrap;
    color: var(--color-font);
    &.ant-table-column-has-sorters:hover {
      background: transparent;
    }
    & .ant-table-column-sorter {
      color: inherit;
    }
    &:last-child {
      background: var(--color-elemBg);
    }
  }
  // удалить разделение в хедере
  &
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  & .ant-table-column-sorter-up.active,
  & .ant-table-column-sorter-down.active {
    color: var(--color-main);
  }

  table {
    border-spacing: 0 5px;
  }

  & .ant-table-tbody > tr.ant-table-placeholder {
    background: transparent !important;
  }
}

.ant-pagination {
  color: var(--color-font);
  align-items: center;
  justify-content: center;
  & .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
  & .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: var(--color-font);
  }
  & .ant-pagination-prev .ant-pagination-item-link,
  & .ant-pagination-next .ant-pagination-item-link,
  & .ant-pagination-item,
  & .ant-pagination-item-link,
  & .ant-select-selector,
  & .ant-select,
  & .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: var(--color-font);
    border-radius: 5px;
    border: none;
    background: var(--color-mainBg);
    border-color: var(--color-grey);
    & a {
      color: var(--color-font);
    }
    & .ant-select-arrow {
      color: var(--color-font);
    }
  }
  & .ant-pagination-item-active {
    font-weight: bold;
    border: 1px dashed var(--color-grey);

    & a {
      color: var(--color-main) !important;
    }
  }
  & .ant-pagination-item:hover,
  & .ant-pagination-prev:hover,
  & .ant-pagination-next:hover,
  & .ant-pagination-disabled {
    opacity: 0.3;
    border-color: var(--color-grey);
  }
  //.ant-pagination-prev:hover .ant-pagination-item-link{
  //  border-color: white;
  //  opacity: 0.3;
  //}
}

//EMPTY
.ant-empty,
.ant-empty-normal {
  color: var(--color-grey);
  font-weight: bold;
  & .ant-empty-description {
    color: var(--color-grey);
  }

  .ant-empty-image {
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: var(--not-found);

    svg {
      display: none;
    }
  }
}

//MODAL
.ant-modal {
  .ant-modal-content {
    background: var(--color-mainBg);
    color: var(--color-font);
    min-width: 520px;
    max-width: inherit;
    border-radius: 5px;
    & .ant-modal-close {
      inset-inline-end: 23px;
      color: var(--color-font);
      transition: all 0.3s;

      & .ant-modal-close-x {
        display: flex;
        width: 40px;
        height: 40px;

        & span {
          width: 30px;
          height: 30px;
          background-size: cover;
          background-image: url(../../../public/img/icon/times-square.svg);

          & svg {
            display: none;
          }
        }
      }

      &:hover {
        opacity: 0.5;
      }
    }

    & .ant-modal-body {
      & .ant-input {
        background: var(--color-elemBg);
      }
      .ant-select:not(.ant-select-customize-input) {
        & .ant-select-selector {
          background: var(--color-elemBg);
        }
      }

      .ant-input-affix-wrapper,
      .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
      .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
      .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
      .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
        background: var(--color-elemBg);
      }
    }
  }
}

//SELECT
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-multiple.ant-select-.ant-select-dropdown.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: var(--color-mainBg);
  color: var(--color-font);
  opacity: 0.6;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: var(--color-elemBg);
}

.ant-select:not(.ant-select-customize-input) {
  & .ant-select-clear {
    background-color: var(--color-mainBg);
    color: var(--color-font);
  }
  & .ant-select-selector {
    color: var(--color-font);
    border: none;
    padding: 12px 15px;
    min-height: 32px;
    border-radius: 5px;
    background-color: var(--color-mainBg);
    & .ant-select-selection-item {
      color: var(--color-font);
    }

    & .ant-select-selection-overflow-item {
      & span {
        border: none;
        align-items: center;
        border-radius: 5px;
        background: var(--color-mainBg);

        & .anticon-close {
          width: 15px;
          height: 15px;
          margin-left: 5px;
          background-size: cover;
          background-image: url(../../../public/img/icon/times-square.svg);

          svg {
            display: none;
          }
        }
      }
    }
  }
}
.ant-select {
  .ant-select-arrow {
    color: var(--color-font);
  }

  .ant-select-selection-placeholder {
    color: var(--color-font);
    opacity: 0.6;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: 15px;
    right: 15px;
  }

  .ant-select-multiple .ant-select-selection-search {
    margin: 0;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    .ant-select-item-option-state {
    display: flex;
    align-items: center;

    span {
      width: 18px;
      height: 18px;
      background-size: cover;
      background-image: url(../../../public/img/icon/check-square.svg);

      svg {
        display: none;
      }
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--color-elemBg);
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 52px;
    padding: 12px 15px !important;
  }

  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 52px;
  }
}

.ant-select-single {
  height: auto;
}

.select-bank {
  & .ant-select-selector {
    height: 60px !important;
    align-items: center;
    background-color: var(--color-mainBg) !important;
  }
}

//PROGRESS
.ant-progress {
  color: var(--color-font);
  & .ant-progress-inner {
    color: var(--color-font);
    & .ant-progress-circle {
      color: var(--color-font);
    }
    & .ant-progress-text {
      color: var(--color-font);
    }
  }
}

//FORM
.ant-form-item {
  margin: 0;
  width: 100%;
  color: var(--color-font);
}
.ant-form-item-control {
  height: 100%;
}

//DATE PICKER
.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background: var(--color-mainBg);
}

.ant-picker-cell-disabled {
  opacity: 0.6;
}

.ant-picker,
.ant-picker.ant-picker-disabled {
  color: var(--color-font);
  min-height: 36px;
  padding: 15px;
  border: none;
  border-radius: 5px;
  border-color: transparent;
  background: var(--color-mainBg);
  &.ant-picker-outlined,
  &.ant-picker-outlined:hover {
    background-color: var(--color-mainBg);
  }

  & .ant-picker-input {
    & input::placeholder {
      color: var(--color-font);
      opacity: 0.35;
    }
    & .ant-picker-clear {
      right: 1px;
      background: var(--color-elemBg);
    }
    & .ant-picker-clear:hover {
      color: black;
    }
  }
  & input {
    color: var(--color-font);
  }
  & span {
    color: var(--color-font);
    & svg {
      background: var(--color-elemBg);
      fill: var(--color-font);
    }
  }
}

.ant-picker-range,
.ant-picker-range .ant-picker-clear {
  span {
    svg {
      background: var(--color-mainBg);
    }
  }
}

.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background: var(--color-elemBg);
}

.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: var(--color-main);

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
  }
}

.ant-picker-range .ant-picker-clear {
  right: 16px;
  background: transparent;

  span {
    svg {
      background: var(--color-mainBg);
    }
  }
}

.ant-picker-input {
  max-width: 125px;
}

.ant-picker-suffix {
  margin-left: 15px;
}

.ant-picker-range .ant-picker-active-bar {
  bottom: 5px;
  height: 3px;
  border-radius: 5px;
  background: var(--color-grey);
}

.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: none;
  margin-left: 10px;
}

.ant-picker:hover,
.ant-picker-focused {
  border: none;
  box-shadow: 0 0 0 2px var(--input-focus);

  &:focus {
    box-shadow: 0 0 0 2px var(--input-focus);
  }
}

.ant-picker.ant-picker-disabled {
  opacity: 0.6;
}

.ant-picker-dropdown {
  color: var(--color-font);
  .ant-picker-panel-container {
    background: var(--color-mainBg);
    color: var(--color-font);
    padding: 10px;
    border-radius: 5px;
    border: 1px dashed var(--border-green);

    & .ant-picker-panel {
      border: none;
    }

    & .ant-picker-header {
      color: var(--color-font);
      border: none;
      border-radius: 5px;
      background: var(--color-elemBg);
      margin-bottom: 5px;

      & button {
        color: var(--color-font);
      }

      & .ant-picker-header-super-prev-btn,
      & .ant-picker-header-super-next-btn,
      & .ant-picker-header-next-btn,
      & .ant-picker-header-prev-btn {
        display: flex;
        align-items: center;
      }
    }

    & .ant-picker-content {
      & .ant-picker-time-panel-column {
        &::-webkit-scrollbar {
          height: 5px;
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: var(--color-elemBg);
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--color-grey);
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
          cursor: pointer;
          background: var(--color-elemBg);
        }
      }

      & .ant-picker-time-panel-column:not(:first-child) {
        border-left: 1px dashed var(--border-green);
      }
    }

    & .ant-picker-footer {
      border: none;
      border-radius: 5px;
      background: var(--color-elemBg);

      & .ant-picker-today-btn {
        font-weight: bold;
        color: var(--color-green);
      }

      & .ant-picker-ranges {
        text-align: center;

        & .ant-picker-ok {
          float: none;

          & button {
            background: var(--color-mainBg);
            color: var(--color-green);
            padding: 5px 30px;

            &:hover {
              opacity: 0.5;
            }
          }
        }

        & .ant-picker-now-btn {
          background: var(--color-mainBg);
          color: var(--color-main);
          padding: 8px 30px;
          border-radius: 5px;
          transition: all 0.3s;
          font-weight: bold;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  & .ant-picker-body {
    color: var(--color-font);

    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
      .ant-picker-cell-inner {
      background: var(--badge-bg-main);
    }

    & .ant-picker-content th,
    & .ant-picker-time-panel-cell-inner {
      color: var(--color-font);
    }

    & .ant-picker-cell-in-view {
      color: var(--color-font) !important;
    }

    & .ant-picker-cell-inner {
      min-width: 28px;
      height: 28px;
      line-height: 28px;
      border-radius: 50%;
    }

    & .ant-picker-cell {
      color: var(--color-grey);
    }
  }

  .ant-picker-range-arrow {
    display: none;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  height: 30px;
  border-top: 1px dashed var(--badge-bg-main);
  border-bottom: 1px dashed var(--badge-bg-main);
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left: 1px dashed var(--badge-bg-main);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 4px;
  border-right: 1px dashed var(--badge-bg-main);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: var(--badge-bg-main);
  opacity: 0.9;
  border-radius: 5px;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
  background: var(--color-main);
  opacity: 0.5;
  border-radius: 0;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: var(--color-font);
  background: var(--badge-bg-main);
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after {
  right: 1px;
  border-radius: 5px;
  background: var(--color-grey);
}

.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before {
  background: var(--color-grey);
}
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  left: 0px;
  border-radius: 5px;
  background: var(--color-grey);
}

.ant-picker-cell::before {
  height: 20px;
}

.ant-picker-week-panel-row:hover td {
  background-color: var(--color-main);
  opacity: 0.7;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  display: none;
}

.ant-picker.v2 {
  width: 100%;

  & .ant-picker-input {
    max-width: 100%;
  }
}

//TIME PICKER
.ant-picker-time-panel {
  & .ant-picker-content {
    & .ant-picker-time-panel-column {
      & .ant-picker-time-panel-cell {
        & .ant-picker-time-panel-cell-inner:hover {
          color: var(--color-font);
          border-radius: 5px;
          background: var(--color-elemBg);
        }
        & .ant-picker-time-panel-cell-inner {
          color: var(--color-font);
          padding: 0;
          text-align: center;
        }
      }
      & .ant-picker-time-panel-cell-selected {
        & .ant-picker-time-panel-cell-inner {
          color: var(--color-font);
          padding: 0;
          text-align: center;
        }
      }
    }
  }
}

//DIVIDER
.ant-divider {
  border-top: 1px dashed var(--color-border);
}

// TOOLTIP
.ant-tooltip-inner {
  font-size: 12px;
  padding: 10px;
  color: var(--color-font);
  text-align: center;
  border-radius: 5px;
  background-color: var(--color-mainBg);
  border: 1px dashed var(--border-green);
}

.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  display: none;
}

.tox-tinymce {
  border: none !important;
  border-radius: 5px !important;
}

// CHECKBOX
.ant-checkbox {
  user-select: none;
  & .ant-checkbox-inner {
    background-color: var(--badge-bg-green) !important;
    border: 1px dashed var(--border-green);
  }

  & .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  & .ant-checkbox:hover .ant-checkbox-inner,
  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--border-green) !important;
    background-color: var(--badge-bg-green);
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--color-green) !important;
  color: var(--color-main);
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid var(--color-green);
  border-top: 0;
  border-left: 0;
}

.ant-checkbox + span {
  color: var(--color-font);
  font-weight: bold;
}

.ant-checkbox-checked::after {
  border: 1px dashed var(--color-green);
}

// NOTIFICATION
.ant-notification-notice {
  min-width: 350px;
  padding: 10px;
  font-weight: bold;
  border-radius: 5px;

  & .ant-notification-notice-icon {
    margin-left: -15px;
    top: calc(50% - 18px);
  }

  & .ant-notification-notice-close {
    top: 10px !important;
    inset-inline-end: 10px !important;

    & .ant-notification-notice-close-icon {
      position: relative;
      top: 1px;
    }
  }

  & .ant-notification-notice-with-icon .ant-notification-notice-message {
    font-size: 16px;
  }

  & .ant-notification-notice-with-icon .ant-notification-notice-description {
    font-size: 12px;
  }
}

.ant-notification-notice-error {
  color: var(--color-red);
  background: var(--color-red);
  border: none;

  & .ant-notification-notice-message,
  & .ant-notification-notice-description {
    color: var(--color-font) !important;
    font-family: 'Nunito', sans-serif;
  }

  & .anticon.ant-notification-notice-icon-error {
    width: 36px;
    height: 36px;
    background-image: url(../../../public/img/icon/times-square.svg);

    & svg {
      display: none;
    }
  }
}

.ant-notification-notice-success {
  color: var(--color-green);
  background: var(--color-green);
  border: none;

  & .ant-notification-notice-message,
  & .ant-notification-notice-description {
    color: var(--color-font) !important;
    font-family: 'Nunito', sans-serif;
  }

  & .anticon.ant-notification-notice-icon-success {
    width: 36px;
    height: 36px;
    background-image: url(../../../public/img/icon/check-square.svg);

    & svg {
      display: none;
    }
  }
}

// progress circle
.ant-progress-circle-trail {
  stroke: var(--color-elemBg);
}

.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: var(--color-main);
}

// COllapse
.ant-collapse {
  border-radius: 5px;
  border: none;
  background: var(--color-elemBg);

  & .ant-collapse-item {
    border-bottom: 1px dashed var(--color-border);

    &:first-child {
      & .ant-collapse-header {
        border-radius: 5px 5px 0 0;
        color: var(--color-font);
        user-select: none;
      }
    }

    &:last-child {
      border: none;

      & .ant-collapse-header {
        border-radius: 0 0 5px 5px;
      }

      & .ant-collapse-content {
        & .ant-collapse-content-box {
          border-radius: 0 0 5px 5px;
          border-bottom: 1px dashed var(--color-border);
        }
      }
    }

    & .ant-collapse-header {
      background: var(--color-mainBg);
      border-radius: 5px;

      & .ant-collapse-expand-icon {
        & svg {
          margin-right: 7px;
        }
      }
    }

    & .ant-collapse-content {
      border: none;
      background: var(--color-mainBg);

      & .ant-collapse-content-box {
        background: var(--color-mainBg);
        border-left: 1px dashed var(--color-border);
        border-right: 1px dashed var(--color-border);
      }
    }

    &.v2 {
      & .ant-collapse-header {
        align-items: center;
        background: var(--color-mainBg);

        & .ant-collapse-expand-icon {
          width: 18px;
          height: 19px;
          margin-right: 7px;
        }
      }
    }
  }
}

.ant-switch {
  background: var(--badge-bg-blue);
  background-image: none;

  &.ant-switch-checked {
    background: var(--color-mainBg);

    & .ant-switch-handle {
      left: calc(100% - 15px - 2px);
    }
  }

  & .ant-switch-handle {
    width: 15px;
    height: 15px;

    &::before {
      background-color: var(--color-main);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }
  }

  & .ant-switch-inner {
    padding-top: 1px;
  }
}

// Slider Rail
.ant-slider {
  & .ant-slider-handle,
  & .ant-slider-handle:active,
  & .ant-slider-handle:hover,
  & .ant-slider-handle:focus {
    &::after {
      left: -3px;
    }
  }
}
.ant-slider-rail {
  background-color: var(--badge-bg-main);
}

.ant-slider-track {
  background-color: var(--color-main);
}

.ant-slider-handle {
  width: 16px;
  height: 16px;
  margin-top: -3px;
  background-color: var(--color-main);
  border: solid 2px var(--color-elemBg);

  &:focus {
    border-color: var(--color-elemBg);
    outline: none;
    box-shadow: 0 0 0 5px rgb(50 169 163 / 20%);
  }
}

.ant-slider {
  &:hover {
    & .ant-slider-rail {
      background-color: var(--badge-bg-main);
    }

    & .ant-slider-track {
      background-color: var(--color-main);
    }

    & .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: var(--color-main);
    }
  }
}

.ant-slider-handle.ant-tooltip-open {
  border-color: var(--color-main);
}

@media screen and (max-width: 1160px) {
  .input-search {
    padding-left: 0;
  }
}
@media screen and (max-width: 650px) {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
    padding: 3px 15px !important;
  }

  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 36px;
  }

  .ant-tabs-salary {
    width: 100%;

    & .ant-tabs-nav .ant-tabs-nav-list,
    & .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      flex-direction: column;
      width: 100%;
    }

    & .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 !important;
    }
  }
}

@media screen and (max-width: 550px) {
  .input-search {
    & .ant-input-group {
      & .ant-input-affix-wrapper {
        & input.ant-input {
          max-width: 130px;
        }
      }
    }
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .select-bank {
    max-width: 300px;
  }

  .ant-btn {
    padding: 7px 10px;
  }
}

@media screen and (max-width: 430px) {
  .select-bank {
    max-width: 200px;
  }
}

.ant-modal {
  .ant-modal-body {
    .buttonUi {
      background: var(--color-elemBg) !important;
    }
  }
}
