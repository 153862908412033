.main {
  background-color: var(--color-mainBg);
  min-height: 100vh;
  height: 100%;
  position: relative;
  & .loadSpin {
    position: fixed;
    inset: 0;
    z-index: 10000;
    height: 100%;
    background-color: #00000073;

    & svg {
      animation: spinner 1500ms infinite linear;
    }
  }
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
