.ErrorAppPage {
  background-color: var(--color-mainBg);
  width: 100vw;
  height: 100vh;
  color: var(--color-font);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  gap: 15px;
  .icon {
    font-size: 5rem;
    color: var(--color-red);
  }
  .title {
    font-size: 2rem;
    font-weight: bold;
  }
  .message {
    font-size: 1.2rem;
  }
  & .errWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 600px;
    background-color: var(--color-elemBg);
    padding: 20px;
    border-radius: 10px;
    & .errMessage {
      font-weight: bold;
      font-size: large;
      color: var(--color-red);
    }
    & .errStack {
      word-break: break-all;
    }
  }
}
