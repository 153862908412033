.container {
  &.fullWidth {
    width: 100%;
  }
  & .labelWrapper {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-start;

    & .label {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      font-weight: bold;
      font-size: var(--font-size);

      & span {
        margin-left: 3px;
        color: var(--color-red);
      }

      & svg {
        position: relative;
        top: -1px;
        margin-right: 7px;
      }
    }

    & .error {
      color: var(--color-red);
      margin-bottom: 15px;
      font-size: var(--font-size);
    }
  }

  & .input {
    background-color: var(--color-mainBg);
  }
}
