.container {
  position: relative;
  color: var(--color-font);

  .body {
    display: flex;
    padding: 20px;
    font-size: var(--font-size);

    & .main {
      width: calc(100% - 325px);
    }
  }

  & .body.admin {
    padding-top: 70px;
  }

  & .body.manager {
    padding-top: 106px;

    & .main {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    .body {
      padding: 20px 20px;

      & .main {
        width: 100%;
        margin: 0;
        padding: 0 10px 10px;
      }
    }

    & .body.admin {
      padding-top: 130px;
    }
  }
}
