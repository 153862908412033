.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 70px;
  padding-right: 20px;
  border-right: 1px solid var(--color-header-second);

  & .logo {
    width: 45px;
    height: 45px;
    background-size: cover;
    background-image: var(--logo);
  }
}

@media screen and (max-width: 555px) {
  .container {
    width: 45px;
    padding-right: 0;

    & .logo {
      max-width: 40px;
    }
  }
}
