.container {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;

  svg {
    margin-right: 7px;
  }
}
.container.blue {
  color: var(--color-blue);
  background-color: var(--badge-bg-blue);
}
.container.green {
  color: var(--color-green);
  background-color: var(--badge-bg-green);
}
.container.red {
  color: var(--color-red);
  background-color: var(--badge-bg-red);
}
