.ant-popover {
  & .ant-popover-arrow {
    &::before {
      background-color: var(--color-grey);
    }
  }
  & .ant-popover-content {
    & .ant-popover-inner {
      background-color: var(--color-grey);
      & .ant-color-picker-input-container {
        display: none;
      }
      & .ant-color-picker-handler {
        border-color: var(--color-mainBg);
        box-shadow: none;
      }
      & .ant-color-picker-slider-container {
        & .ant-slider {
          border: none;
          & .ant-slider-handle {
            &::after {
              border: none;
              box-shadow: none;
            }
            &:hover,
            &:active,
            &:focus {
              box-shadow: none;
              &::after {
                box-shadow: none;
                border: none;
              }
            }
          }
        }
      }
    }
  }
}

.ant-color-picker-trigger {
  border: none;
  background: transparent;
}
