.container {
  background: var(--color-elemBg);
  border-radius: 5px;
  margin: 15px 0 0;
  padding: 1.25rem;
  box-shadow: var(--shadow-box);
}

@media screen and (max-width: 650px) {
  .container {
    padding: 15px;
  }
}
