.container {
  position: relative;
  min-height: 150px;

  & .header {
    position: relative;
    display: flex;
    font-weight: bold;
    font-size: var(--font-size--header);
    padding-bottom: 10px;
    margin-bottom: 20px;
    padding-left: 15px;
    border-bottom: 1px dashed var(--color-elemBg);

    &::after {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      top: calc(50% - 9px);
      left: 0;
      border-radius: 50%;
      animation: flicker 1.5s infinite alternate;
    }
  }
  & .subTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--font-size--small);
    color: var(--color-blue);
    gap: 10px;
    & .noTariff {
      color: var(--color-red);
      font-weight: bold;
    }
    & p {
      font-weight: 600;
      margin: 0;
      text-align: left;
      width: 100%;
      color: var(--color-blue);
      padding: 5px 10px;
      background-color: var(--badge-bg-blue);
      border-radius: 5px;
    }
  }

  & .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 15px;
    padding-top: 10px;
    border-top: 1px dashed var(--color-border-title);
  }

  & .subTitle {
    display: flex;
    align-items: center;
    font-size: var(--font-size--small);
  }

  & .loadSpin {
    & svg {
      animation: spinner 1500ms infinite linear;
    }
  }
}

.transaction {
  position: relative;

  & .desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & .wallet {
      margin: 10px 0;
      font-size: var(--font-size-big);
      color: var(--color-blue);
      cursor: pointer;
    }
  }

  & .input {
    opacity: 1;
    text-transform: uppercase;
  }

  & .copyInput {
    display: flex;

    & input {
      opacity: 1;
      text-align: left;
    }

    & button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 50px;
      border: 1px solid var(--color-elemBg);
      background: var(--color-mainBg);

      &:hover {
        opacity: 0.5;
      }
    }
  }

  & .qr {
    display: inline-block;
    height: auto;
    margin: 0 auto;
    max-width: 150px;
    width: 100%;
    border-radius: 10px;
    padding: 5px 5px 0px 5px;
    border: 1px dashed var(--badge-bg-main);

    & svg {
      border-radius: 10px;
    }
  }

  & .item {
    position: relative;
    text-align: center;
    margin-bottom: 30px;
  }
}

.addWrapper {
  position: relative;

  & .input {
    margin-bottom: 20px;
  }
}

.errorWrapper {
  font-size: var(--font-size-big);
  text-align: center;

  & .reload {
    cursor: pointer;
    width: 54px;
    height: 54px;
    margin: 30px auto 0;
    transition: all 0.3s;

    &:hover {
      opacity: 0.5;
      transform: rotateZ(360deg);
    }
  }
}

@keyframes flicker {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    background-color: var(--color-main);
  }

  20%,
  24%,
  55% {
    background-color: var(--color-mainBg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
